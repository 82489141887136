import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"
import { gradientText } from "src/utils"

const Investors = ({ title, media }) => {
  return (
    <StyledInvestors>
      <VSection id="investors" title={title} media={media} />
    </StyledInvestors>
  )
}

const StyledInvestors = styled.div`
  --section-align: center;
  --tg-text-align: center;
  position: relative;
  z-index: 2;

  --section-max-width: 1200px;

  .title {
    .bold {
      ${gradientText(
        "linear-gradient(90.98deg, #5FC55B 8.61%, #56B089 32.74%)"
      )};
    }
  }

  .fileImage,
  img {
    width: 100%;
  }
`

export default Investors
