import React, {useRef} from "react";
import {Media, VSection} from "components/common";
import styled from "styled-components";
import {device, gradientText} from "utils";

const ChevronIcon = (props) =>
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1864_3500)">
      <path d="M11.9971 3.62395L7.06061 8.56045C6.77932 8.84166 6.39785 8.99963 6.00011 8.99963C5.60236 8.99963 5.2209 8.84166 4.93961 8.56045L0.00610328 3.62695L0.713104 2.91995L5.64661 7.85345C5.74037 7.94719 5.86752 7.99985 6.00011 7.99985C6.13269 7.99985 6.25984 7.94719 6.35361 7.85345L11.2901 2.91695L11.9971 3.62395Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1864_3500">
        <rect width="12" height="12" fill="white" transform="translate(0 12) rotate(-90)"/>
      </clipPath>
    </defs>
  </svg>
;

const HomePartners = ({title, otherReferences}) => {
  const containerRef = useRef();
  const cardRef = useRef();

  const sectionRender = () => <div className="carousel">
    <ChevronIcon className="chevronLeft chevron" onClick={() => scroll(-1)}/>
    <div className="cardContainer" ref={containerRef}>
      {
        otherReferences.map(({logo, blogPost}, index) => <div className="card" ref={cardRef} key={index}>
          <Media media={{ desktop: logo }} className="logo" />
          <p className="subtitle small description">{blogPost.excerpt.childMarkdownRemark.rawMarkdownBody}</p>
          <div style={{flex: 1}}/>
          <div className="footer">
            <div>{blogPost.date}</div>
            <a href={"/blog/" + blogPost.slug}>Learn more...</a>
          </div>
        </div>)
      }
    </div>
    <ChevronIcon className="chevronRight chevron" onClick={() => scroll(1)}/>
  </div>;

  const scroll = (sign) => {
    const crd = cardRef?.current;
    const ctn = containerRef?.current;
    ctn.scrollBy({left: sign * (crd.offsetWidth + parseInt(getComputedStyle(crd).marginLeft) + 1), behavior: "smooth"})
  }

  return <StyledHomePartners partnersCount={otherReferences.length}>
    <VSection id="home_partners" title={title} custom={sectionRender}/>
  </StyledHomePartners>;
}

export default HomePartners

const StyledHomePartners = styled.div`
  --section-align: center;
  --tg-text-align: center;
  position: relative;
  z-index: 2;

  --section-max-width: 1200px;

  --card-animation-duration: 20s;

  .title {
    .bold {
      ${gradientText(
              "linear-gradient(90.98deg, #5FC55B 8.61%, #56B089 32.74%)"
      )};
    }
  }
  
  .chevron {
    height: 32px;
    path {
      fill: var(--body);
    }
    cursor: pointer;
    overflow: initial;
    opacity: .5;
    :hover {
      opacity: 1;
    }
  }
  
  .chevronLeft {
    transform: rotate(90deg);
  }
  
  .chevronRight {
    transform: rotate(-90deg);
  }
  
  .carousel {
    display: flex;
    align-items: center;
    margin-left: calc(0px - var(--section-mobile-padding-left));
    margin-right: calc(0px - var(--section-mobile-padding-right));
    width: 100vw;
    @media ${device.tablet} {
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .cardContainer {
    flex: 1;
    position: relative;
    display: flex;
    overflow: hidden;
    margin-top: 16px;
    padding-top: 40px;
    padding-bottom: 50px;
    @media ${device.tablet} {
      margin-top: 32px;
      margin-bottom: 60px;
    }

    -webkit-mask-image: -webkit-gradient(linear, left center, right center,
      color-stop(0.00,  rgba(0,0,0,0)),
      color-stop(0.05,  rgba(0,0,0,1)),
      color-stop(0.95,  rgba(0,0,0,1)),
      color-stop(1.00,  rgba(0,0,0,0)));
  }
  
  .description {
    margin-top: 16px;
    color: #fff;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    >* {
      font-weight: 400;
      color: #fff;
    }
    >a {
      font-size: 14px;
      color: #6F96F4;
      text-decoration: none;
    }
  }
  
  .card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: linear-gradient(102.81deg, #161E3C 7.18%, rgba(22, 38, 69, 0.23) 95.25%);
    min-width: 280px;
    height: 240px;
    padding: 24px 12px;
    margin: 16px 0;

    @media ${device.tablet} {
      padding: 24px 14px;
      min-width: 320px;
      margin-top: 32px;
    }

    /* PartnerShadow */

    border: 1px solid #9C59D257;
    box-shadow: 0px 31px 60px 4px rgba(0, 47, 168, 0.09), 0px 25px 73px -44px rgba(193, 131, 223, 0.47), 0px 5px 19px 3px rgba(0, 47, 168, 0.15);
    border-radius: 12px;
    
    margin-left: 16px;
    :first-child {
      @media ${device.tablet} {
        margin-left: 48px;
      }
    }
    :last-child {
      margin-right: 16px;
      @media ${device.tablet} {
        margin-right: 48px;
      }
    }
  }

  .logo {
    max-width: 250px;
    max-height: 45px;
    margin: auto;
  }

  .logo .gatsby-image-wrapper-constrained {
    margin: auto;
  }

  .logo img {
    object-fit: contain !important;
  }
`
