import React from "react"
import styled from "styled-components"

import { VSection, Grid, Milestone } from "components/common"

import { gradientText } from "utils"

const HomeMilestones = ({ title, valueProps }) => {
  const renderGrid = () => (
    <>
      <div className="line" />
      <Grid list={valueProps} component={(vp) => <Milestone {...vp} />} />
    </>
  )

  return (
    <StyledHomeMilestones>
      <VSection id="home_hero" title={title} grid={renderGrid} />
    </StyledHomeMilestones>
  )
}

const StyledHomeMilestones = styled.div`
  --section-align: center;
  --tg-text-align: center;

  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 2;

  .title {
    .bold {
      ${gradientText("linear-gradient(180deg, #5371C6 0%, #192464 100%)")};
    }
  }

  .v_gridContainer {
    position: relative;
    --grid-col: 1;
    --grid-gap: var(--sp-64);
    --v-section-grid-top: var(--sp-64);
    --v-section-mobile-grid-top: var(--sp-64);
    .line {
      position: absolute;
      width: 1px;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      background: #212947;
      z-index: 1;
      opacity: 0.7;
    }
  }
`

export default HomeMilestones
