import React from "react"
import styled from "styled-components"

import { CountDown } from "components/common"
import { device, gradientText } from "src/utils"

const AnnouncementBanner = () => {
  return (
    <StyledBanner>
      <BannerBody>
        <BannerSection>
          <BannerSectionHeading>
            <span className="hat">ANNOUNCEMENT</span>{" "}
          </BannerSectionHeading>
          <p>
            The WTR token sale is live! Sign up on:{" "}
          </p>
          <CareersBubble
              href="https://avalaunch.app/project-details?id=62"
              target="_blank"
              rel="deepwaters.xyz"
            >
                Avalaunch
          </CareersBubble>
          <CareersBubble
            href="https://www.poolz.finance/project-details/about/138"
            target="_blank"
            rel="deepwaters.xyz"
          >
            Poolz Finance
          </CareersBubble>
          <CareersBubble
            href="https://solrazr.com/projects/deepwaters"
            target="_blank"
            rel="deepwaters.xyz"
          >
            SolRazr
          </CareersBubble>
          {/* <CountDown/> */}
        </BannerSection>
      </BannerBody>
    </StyledBanner>
  )
}

const StyledBanner = styled.section`
  position: relative;
  z-index: 1;
  display: flex;
  height: 48px;
  border-bottom: 1px solid;
  border-color: #212947;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  z-index: 10;
  max-width: var(--section-max-width);
//   background: var(--card-background);
`

const BannerBody = styled.div`
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  min-width: 0;
  @media ${device.laptop} {
    justify-content: start;
    align-items: self-start;
    background: transparent;
  }
`

const BannerSection = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 700;
    color:var(--tg-title-color);
  }
`

// const BannerSectionHeadingPara = styled.div`
// font-weight:600;
// `;

const BannerSectionHeading = styled.div`
    background:linear-gradient(95.86deg,#975DF5 -1.9%,#BB63F1 52.58%)
    border-radius: 4px;
    color: #fff;
    font-weight: 600;
    line-height:1.5;
    font-size:0.75rem;
    margin-right: 16px;
    padding: 4px 8px;
    text-transform: uppercase;
    border: 1px solid #9C59D257;
    box-shadow: 0px 31px 60px 4px rgba(0, 47, 168, 0.09), 0px 25px 73px -44px rgba(193, 131, 223, 0.47), 0px 5px 19px 3px rgba(0, 47, 168, 0.15);
    @media ${device.laptop} {
        letter-spacing: .1em;
        border-radius: 4px;
      }
    .hat {
        ${gradientText("var(--text_gradient)")};
        font-size:0.9rem;
      }

`

const CareersBubble = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--tg-subtitle-color);
  border-radius: 34px;
  font-weight: 500;
  background: var(--button-background);
  border: 1px solid rgba(125, 151, 255, 0.8);
  font-size: inherit;
  font-weight: 700;
  /* height: 21px;
  width: 28px; */
  line-height: 1.5;
  padding: 3px 8px;
`

export default AnnouncementBanner
