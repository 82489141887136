import React from "react"
import styled from "styled-components"

import { VSection } from "components/common"
import { gradientText } from "src/utils"

const HomeComposability = ({ title, subtitle, media }) => {
  return (
    <StyledHomeComposability>
      <VSection
        id="HomeComposability"
        title={title}
        subtitle={subtitle}
        media={media}
      />
    </StyledHomeComposability>
  )
}

const StyledHomeComposability = styled.div`
  --section-align: center;
  --tg-text-align: center;
  --section-max-width: 1200px;

  --section-padding-top: var(--sp-40);
  --section-padding-bottom: var(--sp-40);

  --tg-max-width: 450px;

  position: relative;
  z-index: 2;

  .title {
    .bold {
      ${gradientText(
        "linear-gradient(90.98deg, #5FC55B 8.61%, #56B089 32.74%)"
      )};
    }
  }
`

export default HomeComposability
