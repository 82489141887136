import React from "react"
import styled from "styled-components"
// import { getGPUTier } from "detect-gpu"

// import Bubbles from "components/animations/Bubbles"

// import DBar from "./DBar"

import Stats from "./Stats"

import { device } from "utils"

// overlay =  5
// const bubbles_z = 1
// light: 5
// parralax = 6
// elements 6
// orb = 10
//hero = 10
// swap - 11
const stas_z_mobile = 1
const stas_z = 5

// nav = 20
// contact = 30
// footer = 31

const PrimaryHud = () => {
  // const [show, setShow] = useState(true)
  // const [show] = useState(true)

  // useEffect(() => {
  //   // detect gpu and hide permance items from low teir gpus
  //   const detect = async () => {
  //     const { tier, gpu } = await getGPUTier()

  //     if (tier >= 2 || gpu.match(/apple|Apple|M1|m1|intel uhd|nvidia|radeon/)) {
  //       setShow(true)
  //     }
  //   }

  //   detect()
  // }, [])

  return (
    <>
      <StatsWrap className="statsWrap">
        <Stats />
      </StatsWrap>

      {/* {show && (
        <DBarWrap>
          <DBar />
        </DBarWrap>
      )} */}

      {/* {show && (
        <BubbleContainer className="bubbleContainer">
          <Bubbles show={show} />
        </BubbleContainer>
      )} */}
    </>
  )
}

// const DBarWrap = styled.div`
//   position: fixed;
//   bottom: var(--sp-24);
//   right: var(--sp-24);
//   z-index: ${stas_z_mobile};
//   display: none;
//   @media ${device.laptop} {
//     display: inline-block;
//     bottom: var(--sp-48);
//     right: var(--sp-48);
//     z-index: ${stas_z};
//   }
// `

const StatsWrap = styled.div`
  position: fixed;
  bottom: var(--sp-24);
  left: var(--sp-24);
  z-index: ${stas_z_mobile};
  display: none;
  @media ${device.laptop} {
    display: inline-block;
    bottom: var(--sp-48);
    left: var(--sp-48);
    z-index: ${stas_z};
  }
`
// const BubbleContainer = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100vh;
//   z-index: ${bubbles_z};
// `

PrimaryHud.defaultProps = {
  hideHud: false,
}

export default PrimaryHud
