import React from "react"
import styled from "styled-components"

import { Cta, VSection, Card2, ValueProp, Grid } from "components/common"
import { device, gradientText, useContentfulComponent } from "utils"

import { useViewportScroll, motion, useTransform } from "framer-motion"

import TestNetCtaGroup from "src/components/cta/TestNetCtaGroup.js"

const HomeHero = ({ title, subtitle, valueProps, ctas, components }) => {
  const { scrollYProgress } = useViewportScroll()
  const opacity = useTransform(scrollYProgress, [0, 0.7, 0.8], [1, 1, 0])

  const { social_ctas } = useContentfulComponent(components)

  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => (
        <Card2 className="home_vp">
          <ValueProp
            {...vp}
            subtitleAs="pSmall"
            titleAs="h3"
            className="hero-vp"
          />
        </Card2>
      )}
    />
  )

  return (
    <StyledHomeHero>
      <VSection
        id="home_hero"
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        // subtitleAs="pSmall"
        grid={renderGrid}
        cta={() => <TestNetCtaGroup ctas={ctas} />}
      />

      <SocialWrap className="socialWrap" style={{ opacity }}>
        <Cta ctas={social_ctas.ctas} className="socal-cta" />
      </SocialWrap>
    </StyledHomeHero>
  )
}

const StyledHomeHero = styled.div`
  position: relative;
  --section-align: center;
  --section-padding-top: 0px;
  --section-mobile-padding-top: 0;

  #home_hero {
    position: relative;
    z-index: 2;

    .half-side-container {
      align-items: center;
      min-height: auto;
      width: 100%;
    }

    .v_tgContainer {
      --tg-text-align: center;

      --section-order: 1;
      --mobile-section-order: 1;
      --tg-max-width: 600px;

      .title {
        word-break: break-word;
      }

      .subtitle {
        max-width: 480px;
        margin: 0 auto;
      }
    } // end of .v_tgContainer

    .v_ctaContainer {
      --section-order: 2;
      --mobile-section-order: 2;
      margin-top: var(--sp-24);
    } // end of .v_ctaContainer

    .v_gridContainer {
      --section-order: 3;
      --section-mobile-order: 3;
      --grid-col: 3;
      --grid-gap: var(--sp-32);
      --grid-mobile-gap: var(--sp-16);
      --tg-text-align: left;
      --vp-align: center;
      position: relative;
      z-index: 2;

      --v-section-mobile-grid-top: var(--sp-64);
      --v-section-grid-top: var(--sp-96);

      .vp_mediaContainer {
        img {
          object-fit: contain !important;
        }
      }

      .home_vp {
        &:nth-child(1) {
          .title {
            .bold {
              ${gradientText(
                "linear-gradient(93.55deg, #738DFF 15.29%, #9D58F8 106.64%)"
              )};
            }
          }
        }
        &:nth-child(2) {
          .title {
            .bold {
              ${gradientText(
                "linear-gradient(95.86deg, #975DF5 -1.9%, #BB63F1 52.58%)"
              )};
            }
          }
        }
        &:nth-child(3) {
          .title {
            .bold {
              ${gradientText(
                "linear-gradient(91.25deg, #D173DB 0.29%, #F176C6 51.07%)"
              )};
            }
          }
        }

        .gatsby-image-wrapper {
          max-width: 240px;
          margin: 0 auto;
        }

        .tgWrap {
          --tg-grid-gap: 4px;

          .subtitle {
            margin-top: var(--sp-4);
          }
        }
      }
    } // end of v_gridContainer
  } // #home_hero
`

const SocialWrap = styled(motion.div)`
  position: fixed;
  bottom: var(--sp-48);
  right: var(--sp-48);
  z-index: 3;
  display: none;

  @media ${device.laptop} {
    display: inline-block;
  }
  .socal-cta {
    display: grid;
    grid-template-columns: 1fr;
    --cta-grid-gap: var(--sp-12);

    button,
    .front {
      border-radius: 50%;

      width: 48px;
      height: 48px;
      padding: 0;
    }
  }
`

export default HomeHero
