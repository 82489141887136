import React from "react"
import styled from "styled-components"

import { VSection, Grid, HValueProps } from "components/common"

import { device } from "utils"

const homeFeatures = ({ valueProps }) => {
  const renderGrid = () => (
    <Grid
      list={valueProps}
      component={(vp) => {
        return (
          <HValueProps odd={vp.idx % 2 === 1} {...vp} subtitleAs="pSmall" />
        )
      }}
    />
  )

  return (
    <StyledhomeFeatures>
      <VSection id="home_features" grid={renderGrid} />
    </StyledhomeFeatures>
  )
}

const StyledhomeFeatures = styled.div`
  --section-align: center;
  --tg-text-align: left;
  --tg-max-width: 565px;
  position: relative;
  z-index: 2;
  --section-max-width: 1400px;
  --section-padding-top: 0;
  --section-mobile-padding-top: 0;

  @media ${device.laptop} {
    --tg-text-align: center;
  }

  .v_gridContainer {
    --grid-col: 1;
    --grid-gap: 24px;
    --grid-mobile-gap: 16px;
    --tg-text-align: left;
    width: 100%;
    ul {
      width: 100%;
    }

    .h_vp {
      flex-direction: column-reverse;

      @media ${device.laptop} {
        flex-direction: row-reverse;
        &:nth-child(odd) {
          flex-direction: row;
        }
      }

      img {
        object-fit: contain !important;
      }
    }
  }
`

export default homeFeatures
