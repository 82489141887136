import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"

const Clock = () => {
  const [time, updateTime] = useState("0:0:0s")

  const timeZone = useMemo(() => {
    const d = new Date()
    const dtf = Intl.DateTimeFormat(undefined, { timeZoneName: "short" })
    return dtf.formatToParts(d).find((part) => part.type === "timeZoneName")
      .value
  }, [])

  useEffect(() => {
    const startTime = () => {
      const checkTime = (i) => {
        if (i < 10) {
          i = "0" + i
        } // add zero in front of numbers < 10
        return i
      }

      const today = new Date()
      let h = today.getHours()
      let m = today.getMinutes()
      let s = today.getSeconds()
      m = checkTime(m)
      s = checkTime(s)

      const fullTime = `${h}:${m}:${s} ${timeZone}`

      updateTime(fullTime)
      // setTimeout(startTime, 1000)
    }

    const interval = setInterval(startTime, 1000)
    return () => clearInterval(interval)
  }, [timeZone])

  return (
    <StyledClock>
      {/* <span className="stat time">00:23:46 gmt</span> */}
      {time}
    </StyledClock>
  )
}

const StyledClock = styled.div``

export default Clock
