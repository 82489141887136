import React from "react"
import styled from "styled-components"

import { VSection, Grid, Milestone, CountDown } from "components/common"

import { gradientText } from "utils"

const HomeCounter = () => {
  

  return (
    <StyledHomeMilestones>
      <VSection id="home_counter" custom={CountDown} />
    </StyledHomeMilestones>
  )
}

const StyledHomeMilestones = styled.div`
  --section-align: center;
  --tg-text-align: center;
  --section-max-width: 1200px;
  --section-align: center;
  --section-padding-top: 80px;
  --section-mobile-padding-top: 10vh;

  .home_counter_section{
    // --section-max-width: 1200px;
    // width: 100%;
    // margin-right: auto;
    // margin-left: auto;
    // --section-align: center;
    // overflow: hidden;
    // --section-mobile-order: auto;


    display: flex;
    align-items: center;
    justify-content: center;
  
    position: relative;
    z-index: 2;
  }

  .v_gridContainer {
    position: relative;
    --grid-col: 1;
    --grid-gap: var(--sp-64);
    --v-section-grid-top: var(--sp-64);
    --v-section-mobile-grid-top: var(--sp-64);
    margin-top:0;
    .line {
      position: absolute;
      width: 1px;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      background: #212947;
      z-index: 1;
      opacity: 0.7;
    }
  }
`

export default HomeCounter
