import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { useContentfulSection, useMeta } from "utils"
import { CountDown, Layout, Seo } from "components/common"
import Footer from "components/navigation/Footer"

import {
  HomeHero,
  HomeComposability,
  HomeFeatures,
  HomeMilestones,
  Investors, HomePartners,HomeCounter
} from "src/sections/home"

import Nav from "src/components/navigation/Nav"
import AnnouncementBanner from "src/components/banner/Announcement"

import MobileNav from "src/components/navigation/mobile/MobileNav"

import Hud from "src/components/hud/Hud"
import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"
import Particles from "src/components/common/shared/Particles"
import ComparativeMatrix from "components/matrix/comparativeMatrix"

const HomePage = ({ data: { home } }) => {
  const meta = useMeta(home)

  const {
    home_hero,
    home_partners,
    home_composability,
    home_features,
    home_milestones,
    investors,
    comparative_matrix,
    bottom_newsletter_cta,
  } = useContentfulSection(home.sections)

  return (
    <StyledHomePage>
      <Layout>
        <Seo {...meta} />
        <Nav />
        <MobileNav />

        <div id="bio-wrap">
          <HomeCounter/>
          <HomeHero {...home_hero} />
          <ComparativeMatrix {...comparative_matrix} />
          <HomePartners {...home_partners} />
          <HomeComposability {...home_composability} />
          <HomeFeatures {...home_features} />

          <Investors {...investors} />

          <div className="particles-wrap">
            <Particles />
          </div>
          <BottomNewsletterCta {...bottom_newsletter_cta} />
          <Footer />
        </div>

        <Hud />
      </Layout>
    </StyledHomePage>
  )
}

const StyledHomePage = styled.div`
  main {
    background: #08111f;
  }

  #bio-wrap {
    position: relative;
    overflow: hidden;

    .particles-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
    }
  }
`

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`

export default HomePage
