import React, { useLayoutEffect, useRef } from "react"
import Vector from "vectory-lib"
import { noise } from "src/utils/hooks/noise.js"
import styled from "styled-components"

const Particles = () => {
  /* Credit goes to: Johan Karlsson and his Post about Particles in Flowfields:
https://codepen.io/DonKarlssonSan/post/particles-in-simplex-noise-flow-field */

  const ctx = useRef(null)
  const canvasRef = useRef(null)

  //   let canvas = null
  //   let ctx = null
  let field = null
  let w = null
  let h = null
  // let fieldColor = null

  let columns = null
  let rows = null

  let particles = null

  let noiseZ = 0
  let particleCount = 300
  let particleSize = 2
  // let fieldSize = 70
  let fieldSize = 30
  let fieldForce = 0.1
  let noiseSpeed = 0.001
  let sORp = true
  let trailLength = 0.6

  let hueBase = 250
  let hueRange = 10
  let maxSpeed = 1

  var ui = new (function () {
    this.particleCount = particleCount
    this.particleSize = particleSize
    this.fieldSize = fieldSize
    this.fieldForce = fieldForce
    this.noiseSpeed = noiseSpeed
    this.simplexOrPerlin = sORp
    this.trailLength = trailLength
    this.maxSpeed = maxSpeed
    this.hueBase = hueBase
    this.hueRange = hueRange

    this.change = function () {
      particleSize = ui.particleSize
      fieldSize = ui.fieldSize
      fieldForce = ui.fieldForce
      noiseSpeed = ui.noiseSpeed
      maxSpeed = ui.maxSpeed
      hueBase = ui.hueBase
      hueRange = ui.hueRange
      // fieldColor = ui.fieldColor
      ui.simplexOrPerlin ? (sORp = 1) : (sORp = 0)
    }

    this.reset = function () {
      particleCount = ui.particleCount
      reset()
    }

    this.bgColor = function () {
      trailLength = ui.trailLength
    }
  })()

  class Particle {
    constructor(x, y) {
      this.pos = new Vector(x, y)
      this.vel = new Vector(Math.random() - 0.5, Math.random() - 0.5)
      this.acc = new Vector(0, 0)
      this.hue = Math.random() * 30 - 15
    }

    move(acc) {
      if (acc) {
        this.acc.addTo(acc)
      }
      this.vel.addTo(this.acc)
      this.pos.addTo(this.vel)
      if (this.vel.getLength() > maxSpeed) {
        this.vel.setLength(maxSpeed)
      }
      this.acc.setLength(0)
    }

    wrap() {
      if (this.pos.x > w) {
        this.pos.x = 0
      } else if (this.pos.x < -this.fieldSize) {
        this.pos.x = w - 1
      }
      if (this.pos.y > h) {
        this.pos.y = 0
      } else if (this.pos.y < -this.fieldSize) {
        this.pos.y = h - 1
      }
    }
  }

  //   canvas = document.querySelector("#particles-canvas")

  function initParticles() {
    particles = []
    let numberOfParticles = particleCount
    for (let i = 0; i < numberOfParticles; i++) {
      let particle = new Particle(Math.random() * w, Math.random() * h)
      particles.push(particle)
    }
  }

  function initField() {
    field = new Array(columns)
    for (let x = 0; x < columns; x++) {
      field[x] = new Array(rows)
      for (let y = 0; y < rows; y++) {
        let v = new Vector(0, 0)
        field[x][y] = v
      }
    }
  }

  function calcField() {
    if (sORp) {
      for (let x = 0; x < columns; x++) {
        for (let y = 0; y < rows; y++) {
          let angle = noise.simplex3(x / 20, y / 20, noiseZ) * Math.PI * 2
          let length =
            noise.simplex3(x / 40 + 40000, y / 40 + 40000, noiseZ) * fieldForce
          field[x][y].setLength(length)
          field[x][y].setAngle(angle)
        }
      }
    } else {
      for (let x = 0; x < columns; x++) {
        for (let y = 0; y < rows; y++) {
          let angle = noise.perlin3(x / 20, y / 20, noiseZ) * Math.PI * 2
          let length =
            noise.perlin3(x / 40 + 40000, y / 40 + 40000, noiseZ) * fieldForce
          field[x][y].setLength(length)
          field[x][y].setAngle(angle)
        }
      }
    }
  }

  function reset(canvas) {
    const bioWrap = document.querySelector("#bio-wrap")
    w = canvas.width = window?.innerWidth
    h = canvas.height = bioWrap?.offsetHeight

    // h = canvas.height = window.innerHeight
    //ctx.current.strokeStyle = fieldColor;
    noise.seed(Math.random())
    columns = Math.round(w / fieldSize) + 1
    rows = Math.round(h / fieldSize) + 1
    initParticles()
    initField()
  }

  function draw() {
    requestAnimationFrame(draw)
    calcField()
    noiseZ += noiseSpeed
    drawBackground()
    drawParticles()
  }

  function drawBackground() {
    // ctx.current.fillStyle = "rgba(4,18,40," + ui.trailLength + ")"
    ctx.current.fillStyle = "rgba(8,17,31," + ui.trailLength + ")"

    // ctx.current.fillStyle = "rgba(0, 0, 0, 0)"
    ctx.current.fillRect(0, 0, w, h)
  }

  function drawParticles() {
    particles.forEach((p) => {
      var ps = (p.fieldSize = Math.abs(p.vel.x + p.vel.y) * particleSize + 0.3)
      ctx.current.fillStyle =
        "hsl(" +
        (hueBase + p.hue + (p.vel.x + p.vel.y) * hueRange) +
        ", 100%, 50%)"
      ctx.current.fillRect(p.pos.x, p.pos.y, ps, ps)
      let pos = p.pos.div(fieldSize)
      let v
      if (pos.x >= 0 && pos.x < columns && pos.y >= 0 && pos.y < rows) {
        v = field[Math.floor(pos.x)][Math.floor(pos.y)]
      }
      p.move(v)
      p.wrap()
    })
  }

  const _reset = useRef(reset)
  const _draw = useRef(draw)

  useLayoutEffect(() => {
    const canvasState = canvasRef.current
    ctx.current = canvasState.getContext("2d")

    _reset.current(canvasState)

    window.addEventListener("resize", _reset.current)
    _draw.current()
  }, [_draw, _reset])

  return <ParticlesCanvas ref={canvasRef} />
}

const ParticlesCanvas = styled.canvas`
  /* mask-image: linear-gradient(transparent, rgba(0, 0, 0, 1), transparent); */
`

export default Particles
